import React from "react";
import { graphql, useStaticQuery } from 'gatsby';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf } from '@fortawesome/free-solid-svg-icons';


import SEO from '../components/SEO';
import Layout from '../components/layout/layout';
import { DownloadItem, Download } from '../components/download/download';
import BanerProduct from '../components/banerProduct/BanerProduct';
import Button from '../components/button/Button';
import NEO from '../assets/doPobrania/NEO.pdf';
import SDR from '../assets/doPobrania/SDR.pdf';

const MatStom = () => {

  const data = useStaticQuery(graphql`
  query {
    neoBaner: file(relativePath: { eq: "NeoBaner.png" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 300) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }

    sdrBaner: file(relativePath: { eq: "SdrBaner.png" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 300) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }

    neoBanerMob: file(relativePath: { eq: "NEO.png" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 300) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }

    sdrBanerMob: file(relativePath: { eq: "SDR.png" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 300) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }


  }
  `);

  const sdrBaner = data.sdrBaner.childImageSharp.fluid
  const neoBaner = data.neoBaner.childImageSharp.fluid
  const sdrBanerMob = data.sdrBanerMob.childImageSharp.fluid
  const neoBanerMob = data.neoBanerMob.childImageSharp.fluid

  return <div>
    <SEO
      title="Materiały Stomatologiczne"
      slug='/mat_stom' />
    <Layout>

      <BanerProduct
        fluid={neoBaner}
        fluidMobile={neoBanerMob}
        to='/neo'
        header1='materiały stomatologiczne'
        header2='Neo Spectra™ST'
        button='Więcej'>
        <p>
          <span>Neo Spectra™ST Effects</span>
          <br />Uzupełniający system do Neo Spectra ST dla odbudowy estetycznej.
        </p>
        <p>
          <span>Neo Spectra™ST</span>
          <br />Uniwersalne odbudowywe wszystkich klasach ubytków.
        </p>
        <p>
          <span>Neo Spectra™ST flow</span>
          <br />Płynny materiał kompozytowy do wszechstronnego zastosowania.
        </p>
      </BanerProduct>

      <BanerProduct
        fluid={sdrBaner}
        fluidMobile={sdrBanerMob}
        to='/sdr'
        header1='materiały stomatologiczne'
        header2='SDR Plus'
        button='Więcej'>
        <p>Ulepszona formuła materiału SDR Plus jeszcze bardziej zwiększa jegowszechstronność, wprowadzając nowe odcienie i poszerzając zakres wskazań.</p>
      </BanerProduct>

      <Download>
        <h3>Do pobrania</h3>
        <p>Więcej informacji szczegółowych na temat produktów znajduje się w folderach produktowych.
          <br />Link znajduje się poniżej.
        </p>

        <DownloadItem>
          <div>
            <FontAwesomeIcon icon={faFilePdf} />
            <p>Materiały Stomatologiczne - informacje szczegółowe SDR</p>
          </div>
          <Button
            href={SDR}
            target='blank'>
            Pobierz
          </Button>
        </DownloadItem>

        <DownloadItem>
          <div>
            <FontAwesomeIcon icon={faFilePdf} />
            <p>Materiały Stomatologiczne - informacje szczegółowe Neo Spectra</p>
          </div>
          <Button
            href={NEO}
            target='blank'>
            Pobierz
          </Button>
        </DownloadItem>

      </Download>
    </Layout>
  </div>
}

export default MatStom
