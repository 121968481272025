import styled from "styled-components"
import media from "styled-media-query"
import { Container } from "../utils/utils"

export const Download = styled(Container)`
  margin-top: 10vh;
  margin-bottom: 10vh;

  h3 {
    margin-bottom: 2rem;
  }
`

export const DownloadItem = styled.div`
  background-color: #d7e7f0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2rem 3rem 2rem 3rem;
  margin-top: 2rem;

  ${media.lessThan("small")`
    flex-direction: column;
    align-items: flex-start;
    `}

  div {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  svg {
    color: rgb(0, 136, 206);
    font-size: 4vh;
    margin-right: 1rem;

    ${media.lessThan("medium")`
    margin-bottom: 1rem;
    `}
  }

  p {
    color: rgb(0, 136, 206);
    font-weight: 700;
    max-width: 300px;
    line-height: 1;

    ${media.lessThan("medium")`
    margin-bottom: 1rem;
    `}
  }
`
